import '../layout/index.scss'

import {loadBlocksFromGraph} from 'blocks/blocks_graph'
import {graphql} from 'gatsby'
import {Press} from 'layout/pages/press'
import React, {useEffect} from 'react'
import {PressQueryQuery} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'
import {TemplateType} from 'types/basictypes'
import {countWords} from 'util/countWords'
import {Template} from 'layout/templates/template'
import {getHeroBackgroundImage} from 'blocks/hero/hero_graph'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

const PressPage: React.FC<TemplateType<PressQueryQuery>> = ({
	data,
	pageContext
}) => {
	const {
		page: {
			path: { langcode },
			field_description,
			field_more,
			dataLayerDate,
			fields: {
				metaFragment,
				previewFragment
			},
			relationships: {
				field_blocks,
				field_content,
				field_background_image,
				field_contactpersons
			}
		},
		allPagePreview: {relatedFragments}
	} = data

	const preview = previewFragmentToPreviewItem(previewFragment)
	const blocks = loadBlocksFromGraph(field_blocks)
	const content = loadBlocksFromGraph(field_content)
	const description = getWysiwygFromField(field_description)

	const contactpersons = field_contactpersons.map(item => ({
		name: item.name,
		function: item.field_contact_function,
		mobile: item.field_contact_mobile_number,
		phone: item.field_contact_phone_number,
		email: item.field_contact_email
	}))

	useEffect(() => {
		// @ts-ignore
		if (window['dataLayer']) {
			// @ts-ignore
			dataLayer.push({
				Author: contactpersons.map(item => item.name),
				ContentType: 'persbericht',
				WordCount: countWords(content),
				ContentTags: preview.tags,
				PublishDate: dataLayerDate
			})
		}
	}, [pageContext.slug])

	return (
		<Template
			preview={preview}
			pageContext={pageContext}
			lang={langcode}
			relatedFragments={relatedFragments}
			relatedJobsFragments={[]}
			content={[...content, ...blocks]}
			metaFragment={metaFragment}
		>
			<Press
				background_image={getHeroBackgroundImage(field_background_image)}
				title={preview.title}
				description={description}
				more={getWysiwygFromField(field_more)}
				content={content}
				blocks={blocks}
				contactpersons={contactpersons}
				preview={preview}
			/>
		</Template>
	)
}

export default PressPage

export const pageQuery = graphql`
	query PressQuery($slug: String!, $allTags: [String]) {
		page: nodePress(fields: {slug: {eq: $slug}}) {
			dataLayerDate: field_date(formatString: "DD/MM/YYYY")
			field_description {
				processed
			}
			field_more {
				processed
			}
			path {
				langcode
			}
			fields {
				metaFragment: meta {...Meta}
				previewFragment: preview {...Preview}
			}
			relationships {
				field_content {...Blocks}
				field_blocks {...Blocks}
				field_contactpersons {
					name
					field_contact_email
					field_contact_function
					field_contact_mobile_number
					field_contact_phone_number
				}
				field_background_image {
					field_overlay_percentage
					...HeroImage
				}
			}
		}

		allPagePreview(
			limit: 13
			sort: {fields: date, order: DESC}
			filter: {
				tags: {in: $allTags},
				has_image: {eq: true},
				type: {in: ["article", "press"]}
			}
		) {
			relatedFragments: nodes {
				...Preview
			}
		}
	}
`
